<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
      ></Workflow>
    </div>

    <v-row justify="center">
      <v-col xl="6" lg="8" md="8" sm="10" xs="12">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="Produit" />
        </div>

        <!-- la progess bar à afficher lors du chargement des données -->
        <v-progress-linear indeterminate :active="loading"></v-progress-linear>

        <DetailViewBoxComponent title="Détail du produit">
          <template v-slot:items>
            <InputFieldComponent label="catégorie" :value="categoryName" />
            <InputFieldComponent label="nom digital" :value="digitalName" />
            <InputFieldComponent label="nom commercial" :value="commercialName" />
            <InputFieldComponent label="activité" :value="activityName" />
            <InputFieldComponent label="sous-activité" :value="subActivityName" />
            <InputFieldComponent label="spécialité médicale" :value="medicalSpeciality" />
            <InputFieldComponent label="description" :value="description" />

            <!-- Les rubriques associées -->
            <InputFieldComponent label="descriptions spécifiques" alignLabel="start" :divider="false">
              <template v-slot:value>
                <div v-for="(speDesc, i) in specificDescriptions" :key="i">
                  <v-row no-gutters align="center" justify="space-between" class="mb-1">
                    <v-col cols="4" >
                      <div>
                        {{ speDesc.establishmentName }}
                      </div>
                    </v-col>
                    <v-col cols="8" class="px-2">
                      <div>
                        {{ speDesc.description }}
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </template>
            </InputFieldComponent>
          </template>
        </DetailViewBoxComponent>

      </v-col>
    </v-row>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import DetailViewBoxComponent from "@/components/ui/DetailViewBoxComponent.vue";
import InputFieldComponent from "@/components/ui/InputFieldComponent.vue";

import { AvecProductsService } from "@/service/conf/avec_products_service.js";
import { ActivityService } from "@/service/sfr/activity_service.js";
import SubActivitiesService from "@/service/sfr/sub_activity_service.js";
import { MedicalSpecialtiesService } from "@/service/practitioners/medical_specialties_service.js";
import EstablishmentsService from "@/service/etablishment/etablishment_service.js";
import { AvecProductCategoryService } from "@/service/conf/avec_product_category_service.js"


import * as exceptions from "@/service/exception_to_message.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";


export default {
  name: "DetailAvecProducts",

  components: { Workflow, TitleAndReturnComponent, DetailViewBoxComponent,
    InputFieldComponent, },
  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /** le service des entités */
      service: null,
      /** le service des activités */
      serviceActivities: null,
      /** le service des sous-activités */
      serviceSubActivities: null,
      /** le service des spécialités médicales */
      serviceMedicalSpecialities: null,
      serviceEstablishment: null,
      serviceCategoryProduct: null,

      /**l'identifiant  à visualiser. */
      entityId: null,

      /**Afficher le bouton de retour */
      showBackButton: false,

      /**les champs à afficher en détail */
      categoryName: null,
      digitalName: null,
      commercialName: null,
      activityName: null,
      subActivityName: null,
      medicalSpeciality: null,
      description: null,
      specificDescriptions: [],
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        this.fields = [];

        // Récupération de l'entité
        let entity = await this.service.getById(this.entityId);
          
        /** Initialisation des datas */
        this.digitalName = entity.digitalName;
        this.commercialName = entity.commercialName;
        this.description = entity.description || "-";


        // Récupération de l'activité si existante
        this.activityName = "-";
        if (entity.activity) {
          let activity = await this.serviceActivities.getActivityById(entity.activity);
          this.activityName = activity.name;
        }

        // Récupération de la sous-activité si existante
        this.subActivityName = "-";
        if (entity.subActivity) {
          let subActivity = await this.serviceSubActivities.getSubActivityById(entity.subActivity);
          this.subActivityName = subActivity.label;
        }
        
        // Récupération de la spécialité médicale si existante
        this.medicalSpeciality = "-";
        if (entity.medicalSpeciality) {
          let medicalSpeciality = await this.serviceMedicalSpecialities.getById(entity.medicalSpeciality);
          this.medicalSpeciality = medicalSpeciality.name;
        }

        // Récupération de la catégorie du produit
        this.categoryName = "-";
        if (entity.category) {
          let catProduct = await this.serviceCategoryProduct.getById(entity.category);
          this.categoryName = catProduct.label;
        }

        // Récupération des nom digitaux des établissement
        let estabName = await this.serviceEstablishment.getDigitalNames();
        // Récupération des description spécifiques du produit
        let specificDesc = await this.service.getAllSpecificDescription(entity.id);
        // Parcours les descriptions spécifiques pour afficher le nom de l'établissement
        for (let speDesc of specificDesc) {
          let foundEstab = estabName.find((e) => e.id == speDesc.establishmentid);

          if (foundEstab) {
            let item = {};
            item.establishmentName = foundEstab.digitalName;
            item.description = speDesc.description;

            this.specificDescriptions.push(item);
          }
        }

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {},
  mounted() {

    this.service = new AvecProductsService(this.$api.getTransmissionsAvecProductsApi());
    this.serviceActivities = new ActivityService(this.$api);
    this.serviceSubActivities = new SubActivitiesService(this.$api);
    this.serviceMedicalSpecialities = new MedicalSpecialtiesService(
      this.$api.getMedicalSpecialitiesApi()
    );
    this.serviceEstablishment = new EstablishmentsService(this.$api);
    this.serviceCategoryProduct = new AvecProductCategoryService(this.$api.getProductCategory());


    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.entityId = this.$route.params.id;
    }

    //on affiche le bouton retour
    this.showBackButton = true;

    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>