var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-center", attrs: { flat: "", tile: "" } },
        [
          _c("Workflow", {
            attrs: {
              width: "600",
              height: "180",
              steps: _vm.workflowSteps,
              currentStep: _vm.workflowIndex,
              labelWidth: 200,
              lineWidth: 140,
            },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { xl: "6", lg: "8", md: "8", sm: "10", xs: "12" } },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-center" },
                [
                  _c("TitleAndReturnComponent", {
                    attrs: { title: "Produit" },
                  }),
                ],
                1
              ),
              _c("v-progress-linear", {
                attrs: { indeterminate: "", active: _vm.loading },
              }),
              _c("DetailViewBoxComponent", {
                attrs: { title: "Détail du produit" },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function () {
                      return [
                        _c("InputFieldComponent", {
                          attrs: {
                            label: "catégorie",
                            value: _vm.categoryName,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            label: "nom digital",
                            value: _vm.digitalName,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            label: "nom commercial",
                            value: _vm.commercialName,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: { label: "activité", value: _vm.activityName },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            label: "sous-activité",
                            value: _vm.subActivityName,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            label: "spécialité médicale",
                            value: _vm.medicalSpeciality,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            label: "description",
                            value: _vm.description,
                          },
                        }),
                        _c("InputFieldComponent", {
                          attrs: {
                            label: "descriptions spécifiques",
                            alignLabel: "start",
                            divider: false,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "value",
                              fn: function () {
                                return _vm._l(
                                  _vm.specificDescriptions,
                                  function (speDesc, i) {
                                    return _c(
                                      "div",
                                      { key: i },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "mb-1",
                                            attrs: {
                                              "no-gutters": "",
                                              align: "center",
                                              justify: "space-between",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "4" } },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        speDesc.establishmentName
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "px-2",
                                                attrs: { cols: "8" },
                                              },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        speDesc.description
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                )
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: {
            color: _vm.snackbarColor,
            timeout: _vm.snackbarTimeout,
            left: _vm.snackbarLeft,
            right: _vm.snackbarRight,
            top: _vm.snackbarTop,
            bottom: _vm.snackbarBottom,
          },
          model: {
            value: _vm.snackbarVisible,
            callback: function ($$v) {
              _vm.snackbarVisible = $$v
            },
            expression: "snackbarVisible",
          },
        },
        [_vm._v(_vm._s(_vm.snackbarMessage))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }